import React from "react"

import { useGlobalState } from "../global/global"
import Layout from "../components/layout"

import "../styles/services.css"
import { navigate } from "gatsby"

const ServicesPage = props => {
  const [state, dispatch] = useGlobalState()

  const setImageSrc = imageName => {
    if (state.lightThemeState) {
      return "/images/light/" + imageName
    }

    if (!state.lightThemeState) {
      return "/images/dark/" + imageName
    }
  }

  return (
    <Layout location={props.location}>
      <div className="services-page">
        <div className="services-page__top">
          <h1 className="services-page__top__title">Services</h1>
          <p className="services-page__top__detail">
            For requirements that are not covered by our current portfolio of
            solutions we provide custom software engineering, design services.
          </p>
        </div>
        <div className="services-page__line"></div>
        <div className="services-page__bottom">
          <div
            id="software-engineering"
            className="services-page__bottom__content"
          >
            <img
              className="services-page__bottom__content__image"
              src={setImageSrc("software_engineering.svg")}
              alt="software engineering"
            />
            <h3 className="services-page__bottom__content__title">
              Software Engineering
            </h3>
            <p className="services-page__bottom__content__detail">
              We engage in feasibility studies, requirement analysis,
              specifications and software development. Our capabilities and
              competencies include backend/frontend, webservices, high
              performance computing, concurrent programming, gpu/cuda
              programming, algorithms, machine vision, machine learning,
              artificial intelligence, databases, linux, operating systems,
              optimizations, embedded programming, game engines, blockchain and
              more.
            </p>
          </div>
          <div id="design" className="services-page__bottom__content">
            <img
              className="services-page__bottom__content__image"
              src={setImageSrc("UX-UI.svg")}
              alt="design"
            />
            <h3 className="services-page__bottom__content__title">Design</h3>
            <p className="services-page__bottom__content__detail">
              We have a team of dedicated visual designers devoted to building
              high quality user interfaces and user experiences. Our design
              capabilities include 2D/3D modeling, animations, assets and
              artwork. We are developing for responsive web design, desktop and
              mobile devices.
            </p>
          </div>
          <div
            id="modeling-and-simulation"
            className="services-page__bottom__content"
          >
            <img
              className="services-page__bottom__content__image"
              src={setImageSrc("Modeling_simulation.svg")}
              alt="modeling and simulation"
            />
            <h3 className="services-page__bottom__content__title">
              Modeling and Simulation
            </h3>
            <p className="services-page__bottom__content__detail">
              Our modeling and simulation capabilities include micro agent-based
              modeling, concurrent programming, gpu accelerations, model
              development and user-interfaces. We also do 2D and 3D graphics and
              user interfaces for the simulations enabling solutions such as
              digital twins, training, serious games and more.
            </p>
          </div>
          {/* <div
            id="digital-innovation-management"
            className="services-page__bottom__content"
          >
            <img
              className="services-page__bottom__content__image"
              src={setImageSrc("Digital_innovation.svg")}
              alt="digital innovation management"
            />
            <h3 className="services-page__bottom__content__title">
              Digital Innovation Management
            </h3>
            <p className="services-page__bottom__content__detail">
              Software and enabling technologies are only one part of the
              equation. In order to get return of investment and business growth
              one needs to embark on a journey of change management, business
              model innovation and digital business transformation. We have a
              team of highly experienced business developers with a deep
              understanding of how to match digital technology investments with
              business outcomes. We have decades of hands-on experience with
              disruptive innovation, lean business development, technology
              management, IP management and digital strategy. We offer
              customized services related to the full suite of digital
              innovation management. We have a solid track record supporting
              deep-tech startups and innovation projects and we designed the
              services portfolio so that it covers the entirety of the
              innovation value chain and at any Technology Readiness Levels
              (TRLs) for any purpose, such as fund raising, product launch,
              project management, licensing and transactions. The engagement can
              be done in various formats such as workshops, strategy meetings,
              benchmarking, analysis, business consulting etc.
            </p>
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default ServicesPage
